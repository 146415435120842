@use "./variables" as *;

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin smallMobile {
  @media screen and (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin largeDesktop {
  @media screen and (min-width: $max-width) {
    @content;
  }
}
