//brand colours
$primary-white: #fff;
$primary-navy: #273852;
$primary-pink: #faece8;
$primary-yellow: #f6c609;
$secondary-yellow: #f7d862;
$primary-blue: #f0f2fb;
$secondary-blue: #6e99de;

//padding
$mobile-padding: 2.2rem 2rem;
$tablet-padding: 3rem 4rem;
$desktop-padding: 3.8rem 6rem;

//breakpoints
$mobile-breakpoint: 375px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;

$max-width: 1366px;
