@use "../partials/variables" as *;
@use "../partials/mixins" as *;

.cta {
  padding: 0.9rem 3.4rem 0.9rem 2rem;
  border: none;
  border-radius: 30px;
  margin: 1rem 0;
  background-color: $primary-yellow;
  font-weight: bold;
  position: relative;
  font-size: 18px;

  &__chevron {
    position: absolute;
    top: 13px;
    right: 1.8rem;
    transition: 1s all ease-in-out;
  }

  &:hover {
    background-color: $secondary-yellow;

    * {
      right: 1.2rem;
    }
  }
}
