@use "../../scss/partials/mixins" as *;
@use "../../scss/partials/variables" as *;

.header {
  position: relative;
  padding: $mobile-padding;
  height: 29rem;
  background-image: url("../../../public/assets/images/img-header-background.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include flex(row, center, center);
  @include tablet {
    padding: $tablet-padding;
  }
  @include desktop {
    padding: $desktop-padding;
    height: 42rem;
    background-position: bottom;
  }

  &__title {
    text-align: center;
    color: $primary-white;
    width: 245px;
    z-index: 1;
    @include desktop {
      width: 100%;
      @include flex(row, center, center);
    }

    h1 {
      border-bottom: 1px solid $primary-white;
      font-size: 58px;

      @include desktop {
        border-bottom: none;
        border-right: 1px solid $primary-white;
        padding-right: 2rem;
        margin-right: 2rem;
        font-size: 6.2rem;
      }
    }

    p {
      padding: 0.8rem 0;
      font-family: "aileron", sans-serif;
      font-weight: 100;
      @include desktop {
        width: 360px;
        text-align: left;
        font-size: 2rem;
      }
    }
  }
}

.no-webp {
  header {
    background-image: url("../../../public/assets/images/img-header-background.jpg");
  }
}
