@use "./variables" as *;
@use "./typography";

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

*,
*:before,
*:after,
html {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: $primary-navy;
  font-size: 16px;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

a,
button {
  color: inherit;
}

li {
  list-style: none;
}
