@use "../../scss/partials/variables" as *;
@use "../../scss/partials/mixins" as *;

.navbar {
  width: 100%;
  position: absolute;
  top: 0;
  padding: inherit;
  z-index: 1;
  max-width: $max-width;

  &__logo {
    width: 182px;
    @include desktop {
      width: 264px;
    }
  }
}
