@use "../../scss/partials/variables" as *;
@use "../../scss/partials/mixins" as *;

.wrapper,
.waves {
  width: 100%;
  @include tablet {
    margin: auto;
    padding: $tablet-padding;
  }
  @include desktop {
    padding: $desktop-padding;
  }
}
.wrapper {
  max-width: $max-width;
  position: relative;
  @include tablet {
    padding-bottom: 15rem;
    display: flex;
    flex-direction: column;
  }
}

.content {
  padding: $mobile-padding;
  @include flex(row, center, center);
  flex-wrap: wrap;
  width: 100%;

  @include tablet {
    justify-content: flex-start;
    width: 80%;
    padding: $tablet-padding;
    margin: 5rem 0;
  }

  &__textWrapper {
    max-width: 360px;
    @include tablet {
      max-width: 60%;
    }
    @include desktop {
      max-width: 80%;
    }
  }

  h2 {
    font-size: 40px;
    margin: 1rem 0;
    width: 100%;
    @include desktop {
      font-size: 56px;
    }
  }

  p {
    line-height: 22px;
    margin: 0.5rem 0;
    a {
      font-weight: bold;
    }
  }

  img {
    width: 100%;
    max-width: 360px;
    margin: 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 5px;
    @include tablet {
      position: absolute;
    }
  }
}

.contentFirst {
  background-color: $primary-blue;
  img {
    @include tablet {
      top: 12rem;
      right: 1rem;
      max-width: 380px;
    }
    @include desktop {
      right: 6rem;
    }
  }
}

.contentMiddle {
  background-color: $primary-pink;
  padding-bottom: 10rem;

  @include tablet {
    padding: $tablet-padding;
    align-self: flex-end;
    justify-content: flex-end;
  }
  img {
    @include tablet {
      left: 1rem;
      bottom: 20rem;
      max-width: 380px;
    }
    @include desktop {
      left: 6rem;
      bottom: 14rem;
    }
  }
}

.contentLast {
  @include tablet {
    align-self: center;
    background-color: rgba($color: #fff, $alpha: 0.1);
  }
  h2,
  p {
    color: $primary-white;
  }
  img {
    box-shadow: none;
    display: none;
    @include tablet {
      display: block;
      right: -4rem;
      top: 2rem;
      max-width: 470px;
    }
    @include desktop {
      right: 0;
      bottom: 16rem;
      max-width: 450px;
    }
  }
}

.waves {
  background-color: $primary-navy;
  width: 100%;
  padding-bottom: 10rem;
  position: relative;
  @include tablet {
    padding-bottom: 0;
  }

  &__wave {
    position: absolute;
    width: 100%;
    height: 80px;
    min-height: 40px;
    margin-bottom: -7px; /*Fix for safari gap*/
    top: -60px;
    left: 0;
    @include tablet {
      top: -120px;
      min-height: 90px;
      max-height: 180px;
      height: 140px;
    }
  }

  &__parallax > use {
    transition: all;
    height: 150px;
    animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  &__parallax > use:nth-child(1) {
    opacity: 0.5;
    animation-delay: -2s;
    animation-duration: 7s;
  }
  &__parallax > use:nth-child(2) {
    opacity: 0.5;
    animation-delay: -7s;
    animation-duration: 7s;
  }
  &__parallax > use:nth-child(3) {
    opacity: 0.5;
    animation-delay: -4s;
    animation-duration: 10s;
  }
  &__parallax > use:nth-child(4) {
    opacity: 0.5;
    animation-delay: -10s;
    animation-duration: 10s;
  }
  &__parallax > use:nth-child(5) {
    animation-delay: -6s;
    animation-duration: 13s;
  }
  &__parallax > use:nth-child(6) {
    animation-delay: -13s;
    animation-duration: 13s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, -5px, 0);
    }
    50% {
      transform: translate3d(0, 2px, 0);
    }
    100% {
      transform: translate3d(85px, -5px, 0);
    }
  }
}
