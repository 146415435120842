@use "../../scss/partials/variables" as *;
@use "../../scss/partials/mixins" as *;

.footer {
  position: relative;
  height: 17rem;
  background-color: $secondary-blue;
  width: 100%;
  @include flex(column, center, flex-start);
  @media screen and (min-width: 550px) {
    height: 14.8rem;
  }
  @include desktop {
    height: 15rem;
  }

  p {
    @include desktop {
      font-size: 18px;
    }
  }

  &__content {
    margin: auto;
    position: absolute;
    z-index: 1;
    padding: $mobile-padding;
    @include tablet {
      padding: 0rem 6rem;
    }
    @include largeDesktop {
      width: 100%;
      position: relative;
      max-width: $max-width;
    }
  }

  &__wrapper {
    margin: 1rem 0;
    font-weight: bold;
    @include tablet {
      @include flex(row, flex-start, center);
    }
  }

  &__socials {
    border-bottom: 1px solid black;
    @include tablet {
      @include flex(row, flex-start, center);
    }
  }

  &__social {
    @include flex(row, flex-start, center);
    margin: 1rem 0;
    flex-wrap: wrap;
    @include tablet {
      margin-right: 2rem;
    }

    p {
      @include smallMobile {
        display: none;
      }
      @media screen and (max-width: 355px) {
        display: none;
      }
    }

    img {
      margin-right: 10px;
      width: 1.2rem;
    }
  }

  &__image {
    width: 100%;
    position: absolute;
    bottom: 50%;
    z-index: 0;
    @media screen and (min-width: 550px) {
      display: none;
    }
  }

  &__imageDesktop {
    display: none;
    @media screen and (min-width: 550px) {
      bottom: 100px;
      right: 0;
      display: block;
      position: absolute;
    }

    @include desktop {
      width: 905px;
      bottom: 0;
    }
  }
}
