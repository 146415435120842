@font-face {
  font-family: "SourceSerif-Bold";
  src: url("../../../public/assets/fonts/SourceSerifPro-Bold.ttf");
}

body {
  font-family: "aileron", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "SourceSerif-Bold", serif;
}
